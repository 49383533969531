

import { syncRef, useUrlSearchParams, useVModel, useVModels } from '@vueuse/core'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BarMenu from './BarMenu.vue'

export default defineComponent({
  components: { BarMenu },
  props: {
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    factory: {
      type: String,
    },
    customer: {
      type: String,
    },
    procedure: {
      type: String,
    },
    status: {
      type: String,
    },
    sort: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
    },
    // customers: {
    //   type: Array
    // },
    // procedures: {
    //   type: Array
    // },
    // factories: {
    //   type: Array
    // },
    name: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const {
      from: vFrom,
      to: vTo,
      factory: selectedFactory,
      procedure: selectedProcedure,
      customer: selectedCustomer,
      status: selectedStatus,
      name: unitname,
      tag: selectedTag,
    } = useVModels(props, emit)
    // const settings = ref(JSON.parse(localStorage.getItem('settings') || '{}'))

    const urlParams = useUrlSearchParams()
    onMounted(() => {
      if (urlParams.from) {
        fromVal.value = urlParams.from as string
      }
      if (urlParams.to) {
        toVal.value = urlParams.to as string
      }
      if (urlParams.fac) {
        selectedFactory.value = urlParams.fac as string
      }
      if (urlParams.proc) {
        selectedProcedure.value = urlParams.proc as string
      }

      if (urlParams.customer) {
        selectedCustomer.value = urlParams.customer as string
      }

      if (urlParams.status) {
        selectedStatus.value = urlParams.status as string
      }
      if (urlParams.sort) {
        sortModel.value = urlParams.sort as string
      }
      if (urlParams.unit) {
        unitname.value = urlParams.unit as string
      }
      if (urlParams.tag) {
        selectedTag.value = urlParams.tag as string
      }
    })
    // for date
    const fromVal = ref('')
    const toVal = ref('')
    // const fromVal = ref(moment().subtract(7, 'd').format('YYYY-MM-DDTHH:mm'))
    // const toVal = ref(moment().format('YYYY-MM-DDTHH:mm'))
    watch(fromVal, () => {
      // vFrom.value = String(Math.floor(moment(fromVal.value).startOf('day').valueOf() / 1000))
      vFrom.value = fromVal.value
    })
    watch(toVal, () => {
      // vTo.value = String(Math.floor(moment(toVal.value).endOf('day').valueOf() / 1000))
      vTo.value = toVal.value
    })
    const vSort = useVModel(props, 'sort', emit)
    const sortModel = ref('dateTime')
    const sortAsc = ref(false)
    const sort = computed(() => {
      let s = [] as string[]
      switch (sortModel.value) {
        case 'dateTime':
          s = ['created.seconds', 'created.nanos']
          break
      }

      if (!sortAsc.value) {
        for (const i in s) {
          s[i] = '-' + s[i]
        }
      }

      return s.join(',')
    })
    syncRef(sort, vSort)

    const queryParams = computed(() => ({
      sort: sortModel.value,
      from: fromVal.value,
      to: toVal.value,
      fac: selectedFactory.value,
      customer: selectedCustomer.value,
      status: selectedStatus.value,
      proc: selectedProcedure.value,
      unit: unitname.value,
      tag: selectedTag.value,
    }))

    const router = useRouter()
    const route = useRoute()
    const clear = () => {
      selectedFactory.value = ''
      selectedCustomer.value = ''
      selectedStatus.value = ''
      selectedProcedure.value = ''
      unitname.value = ''
      fromVal.value = ''
      toVal.value = ''
      selectedTag.value = ''
    }

    watch([sort, fromVal, toVal, selectedTag, selectedFactory, selectedCustomer, selectedStatus, selectedProcedure, unitname], () => {
      router.replace({
        query: Object.fromEntries(
          Object.entries(
            Object.assign({}, route.query, queryParams.value),
          ).filter(([, v]) => !!v)),
      })
    })

    return {
      sortModel,
      sortAsc,
      fromVal,
      toVal,
      selectedFactory,
      selectedCustomer,
      selectedStatus,
      selectedProcedure,
      selectedTag,
      clear,
      unitname,
    }
  },
})
