
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useUsersStore } from '@/stores/users/users'
import debounce from 'lodash/debounce'
import moment from 'moment'
import SpotlightFilter from '@/components/SpotlightFilter.vue'
import useSession from '@/models/get/session'
import useSpotlights from '@/models/list/spotlight_videos'
import { useIntersectionObserver } from '@vueuse/core'

export default defineComponent({
  name: 'Spotlights',
  components: {
    SpotlightFilter,
  },
  props: {
    unit: {
      type: String,
    },
  },
  setup() {
    const usersStore = useUsersStore()
    const {
      userGroup,
    } = storeToRefs(usersStore)

    const { fetchItems: fetchSpotlights, items: spotlights, nextPage } = useSpotlights()
    const { fetchItem: fetchSession, item: foundSession } = useSession()
    // const from = ref(moment().startOf('day').format('YYYY-MM-DDTHH:mm'))
    // const to = ref(moment().endOf('day').format('YYYY-MM-DDTHH:mm'))
    const from = ref('')
    const to = ref('')
    const counts = ref<number[]>([])
    const procedure = ref('')
    const sort = ref<string>('')
    const cycleTime = ref(new Map())
    // const factory = ref('')
    // const customer = ref('')
    const status = ref('')
    const presetMap = ref(new Map<string, string>())
    const factoryMap = ref(new Map<string, [string]>())
    const customers = ref<string[]>([])
    const procedures = ref<string[]>([])
    const factories = ref<string[]>([])
    const factorySelection = ref<string[]>([])
    const o = ref()
    const localDates = ref<string[]>([])
    const unitname = ref('')
    const tag = ref('')
    const statusMap = new Map([
      ['UNSET', 0],
      ['ASSIGNED', 1],
      ['DISCARDED', 3],
      ['REJECTED', 4],
      ['COMPLETED', 5],
      ['APPROVED', 6],
      ['GT_GENERATED', 7],
    ])
    const loader = ref()
    useIntersectionObserver(
      loader,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          nextPage()
          console.log('scroll')
        }
      },
    )
    const toTimestamp = (value: string): number => {
      const date = new Date(value)
      return date.valueOf() / 1000
    }

    const tagSplit = (tags: Array<string>) => {
      let temp = ''
      for (let i = 0; i < tags.length; i++) {
        temp += tags[i] + '  '
      }
      return temp.slice(0, -1)
    }

    const spotlightFilter = computed(() => {
      const q: unknown[] = []

      if (userGroup.value) {
        q.push({ manufacturer: { $in: userGroup.value } })
      }

      if (from.value || to.value) {
        q.push({ 'created.seconds': { $gte: toTimestamp(from.value) } })
        q.push({ 'created.seconds': { $lte: toTimestamp(to.value) } })
      }
      if (status.value) {
        const n = statusMap.get(status.value)
        if (n === 0) {
          q.push({ overallStatus: { $exists: false } })
        } else {
          q.push({ overallStatus: n })
        }
      }
      // if (customer.value) {
      //   q.push({ customer: customer.value })
      // }
      if (procedure.value) {
        q.push({ procedure: procedure.value })
      }
      if (tag.value) {
        const t = tag.value.split(',')
        if (t.length === 1) {
          q.push({ tags: { $in: [tag.value] } })
        } else {
          const temp = ref<unknown[]>([])
          for (const s of t) {
            const c = { tags: s }
            temp.value.push(c)
          }
          q.push({ $and: temp.value })
        }
      }
      // if (factory.value) {
      //   q.push({ factory: factory.value })
      //   // q.push({ session: { $in: factoryMap.value.get(factory.value) } })
      // }
      if (unitname.value) {
        q.push({
          unit: {
            $regex: unitname.value,
          },
        })
      }
      return q.length > 0
        ? JSON.stringify({
          $and: q,
        })
        : ''
    })
    const toLocalDate = (date: string): string => {
      const local = moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
      return local
    }
    const factoryMapping = async (session:string) => {
      const sessionID = String(session?.split('/')[1])
      // await fetchSession({ session: sessionID })
      // console.log(factoryMap.value)
      if (!presetMap.value.has(sessionID)) {
        if (sessionID) {
          await fetchSession({ session: sessionID })
          const fac = foundSession.value.source
          if (fac) {
            const temp = factoryMap.value.get(fac)
            if (temp) {
              temp.push('sessions/' + sessionID)
              factoryMap.value.set(fac, temp)
            } else {
              factoryMap.value.set(fac, ['sessions/' + sessionID])
            }
            presetMap.value.set(sessionID, fac)
          }
          return fac
        }
      } else {
        const fac = presetMap.value.get(sessionID)
        return fac
      }
    }
    // const gatherData = async () => {
    //   o.value = null
    //   factories.value = []
    //   for (let i = 0; i < spotlights.value.length; i++) {
    //     const sessionID = String(spotlights.value[i].session?.split('/')[1])
    //     if (!presetMap.value.has(sessionID)) {
    //       if (sessionID) {
    //         await fetchSession({ session: sessionID })
    //         const fac = foundSession.value.source
    //         if (fac) {
    //           const temp = factoryMap.value.get(fac)
    //           if (temp) {
    //             temp.push('sessions/' + sessionID)
    //             factoryMap.value.set(fac, temp)
    //           } else {
    //             factoryMap.value.set(fac, ['sessions/' + sessionID])
    //           }
    //           presetMap.value.set(sessionID, fac)
    //           factories.value.push(fac)
    //           factorySelection.value.push(fac)
    //         }
    //       }
    //     } else {
    //       const fac = presetMap.value.get(sessionID)
    //       if (fac) {
    //         factories.value.push(fac)
    //       }
    //     }
    //   }
    // }
    const updateSearch = debounce(async () => {
      fetchSpotlights({
        pageSize: 60,
        filter: spotlightFilter.value,
        orderBy: sort.value,
      })
      // await gatherData()
      console.log(spotlights.value)
    }, 250)
    // const fetch = debounce(async () => {
    //   await fetchSpotlights({
    //     pageSize: -1,
    //     filter: spotlightFilter.value,
    //     orderBy: sort.value
    //   })
    //   console.log(spotlights.value)
    //   await gatherData()
    // }, 250)

    onMounted(async () => {
      await Promise.all([
        // fetch()
        updateSearch(),
      ])
    })

    watch([spotlightFilter, sort], () => {
      updateSearch()
      // fetch()
    })
    return {
      from,
      to,
      counts,
      procedure,
      procedures,
      cycleTime,
      spotlights,
      o,
      // factory,
      // customer,
      status,
      sort,
      customers,
      factories,
      factorySelection,
      localDates,
      unitname,
      loader,
      tag,
      toLocalDate,
      factoryMapping,
      tagSplit,
    }
  },
})
